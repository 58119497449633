import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiModelCityInterface } from '../../api/model/city.interface';
import ApiModelCountryInterface from '../../api/model/country.interface';
import ApiModelStoreSettingsIndustryInterface from '../../api/model/store/industry.interface';
import ApiModelStoreSettingsCategoryInterface from '../../api/model/store/store-category.interface';
import { ApiModelStoreSettingsInfoInterface } from '../../api/store-info.interface';
import appStoreFactory from '../../app/store/factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { InputTextChangeEventInterface } from '../../common/components/Input';
import Catch from '../../common/decorators/catch-error';
import { SettingsApiStoreSettingsServiceFactory } from '../api/store/store-settings-factory';
import defaultStoreBusinessData from './default-store-business-data';
import { InputCheckboxChangeEventInterface } from '../../common/components/Input/Checkbox/change-event.interface';
import { revalidate } from '../../common/helpers/revalidate/revalidate';
import { businessTypeEnum } from '../api/store/business-type.enum';
import { BusinessDetailsSchemaType } from '../types/business-detail-schema.interface';
import { StoreBusinessPlaceApiFiltersInterface } from '../../orders/api/drafts/store-business-place-store.interface';
import { SocialMediaTypeEnum } from '../../api/model/store/social-media-enum';
import { AboutStoreInfoApiFiltersInterface } from '../../orders/api/drafts/about-store.interface';
import ApiModelUserInterface from '../../api/model/user.interface';
import { VatSettingsDataTaxSettingsInterface, VatSettingsInterface } from '../../api/model/vat-settings.interface';
import ApiModelBusinessLocationInterface from '../../api/model/store/business-location.interface';
import { AxiosError } from 'axios';
import { ApiBaseDataResponseObjectType } from '../../api/types';

const service = SettingsApiStoreSettingsServiceFactory();
@Module({
  dynamic: true,
  name: 'settingsStoreInfo',
  store: appStoreFactory(),
  namespaced: true,
})
class StoreSettingsModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingBusinessPlace: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingCities: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public isSubmitting: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: ApiModelStoreSettingsInfoInterface | null = null;
  public cities: ApiModelCityInterface[] = [];
  public countries: ApiModelCountryInterface[] = [];
  public vatActivate = false;
  public vatSettings: VatSettingsDataTaxSettingsInterface | null = null;
  public error: Error | null = null;
  public businessType: businessTypeEnum = businessTypeEnum.individual;
  public errorMessage = '';

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_BUSINESS_PLACE(): void {
    this.loadingBusinessPlace = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private UPDATE(): void {
    this.isSubmitting = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private UPDATE_CITIES(): void {
    this.loadingCities = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS__COMPLETE(): void {
    this.loadingCities = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: ApiModelStoreSettingsInfoInterface): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = JSON.parse(JSON.stringify(data));
    if (this.data?.user?.store_business_data?.civil_id) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.user.store_business_data.civil_id =
        this.data.user.store_business_data.civil_id == '0' ? null : this.data.user.store_business_data.civil_id;
    }
  }
  @Mutation
  private FETCH_BUSINESS_PLACE_SUCCESS(data: ApiModelBusinessLocationInterface): void {
    this.loadingBusinessPlace = AppStoreReadyStateEnum.loaded;
    if (this.data) this.data.user.business_location = data;
  }

  @Mutation
  private ON_CHANGE_BUSINESS_TYPE(type: string): void {
    if (this.data?.user?.store?.store_business_data) {
      this.data.user.store.store_business_data['business_type'] = type;
    }
  }

  @Mutation
  private ON_CHANGE_STORE_TITLE(value: string): void {
    if (this.data?.user.store) {
      this.data.user.store.title = value;
    }
  }

  @Mutation
  private ON_CHANGE_STORE_EN_TITLE(value: string): void {
    if (this.data?.user.store) {
      this.data.user.store.ssl = value;
    }
  }

  @Mutation
  private ON_CHANGE_STORE_EMAIL(value: string): void {
    if (this.data?.user.store) {
      this.data.user.store.email = value;
    }
  }

  @Mutation
  private ON_CHANGE_STORE_PHONE(value: string): void {
    if (this.data?.user?.store) {
      this.data.user.store.phone = value;
    }
  }

  @Mutation
  private ON_CHANGE_TIME_ZONE(value: string): void {
    if (this.data?.user?.store) {
      this.data.user.store.timezone = value;
    }
  }

  @Mutation
  private ON_CHANGE_COUNTRY(SelectedCountry: ApiModelCountryInterface): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.country = SelectedCountry;
    }
  }

  @Mutation
  private ON_CHANGE_NATIONAL_ADDRESS_CERTIFICATE(file: File | null): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.national_address_certificate = file;
    }
  }

  @Mutation
  private ON_CHANGE_CITY(selectedCity: ApiModelCityInterface): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.city = selectedCity;
    }
  }

  @Mutation
  private SET_DISTRICT_CHANGE(value: string): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.district = value;
    }
  }

  @Mutation
  private SET_STREET_ADDRESS(value: string): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.street = value;
    }
  }

  @Mutation
  private SET_BUILDING_NO_ADDRESS(value: number): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.building_no = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private SET_POSTAL_CODE_ADDRESS(value: number): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.postal_code = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private SET_ADDIONAL_POSTAL_CODE_ADDRESS(value: number): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.additional_postal_code = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private SET_ADDRESS_DISPLAY(value: boolean): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.show_location = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private ON_CHANGE_COMMERICAL_NUMBER(value: string): void {
    if (this.data?.user?.store) {
      this.data.user.store.commercial_registration_number = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private ON_CHANGE_COMMERICAL_REGISTRATION_NUMBER(value: string): void {
    if (this.data?.user?.store) {
      this.data.user.store.commercial_registration_number = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private SET_STORE_BUISNESS_DATA(value: string): void {
    if (this.data?.user?.store.store_business_data) {
      this.data.user.store.store_business_data.business_place_status = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private ON_CATEGORY_CHANGE(value: ApiModelStoreSettingsCategoryInterface): void {
    if (this.data?.user?.store) {
      this.data.user.store.category = value;
    }
  }

  @Mutation
  private ON_INDUSTRY_CHANGE(value: ApiModelStoreSettingsIndustryInterface): void {
    if (this.data?.user?.store) {
      this.data.user.store.industry = value;
    }
  }

  @Mutation
  private ON_CHANGE_NUMBER_OF_BRANCHES(value: number): void {
    if (this.data?.user?.store?.store_business_data) {
      this.data.user.store.store_business_data.branch_no = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private IS_COMMERCIAL_DISPLAY(value: boolean): void {
    if (this.data?.user?.store) {
      this.data.user.store.commercial_registration_number_activation = value ? 1 : 0; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private ON_LOCATION_CHANGE(value: { lat: number; lng: number }): void {
    if (this.data?.user?.business_location) {
      this.data.user.business_location.lat = value.lat;
      this.data.user.business_location.lng = value.lng;
    }
  }

  @Mutation
  private SET_DEFAULT_BUSINESS_DATA(): void {
    if (this.data?.user?.store?.store_business_data) {
      this.data.user.store.store_business_data = JSON.parse(JSON.stringify(defaultStoreBusinessData)); // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private ON_DOCUMENT_CHANGE(value: { type: string; image: File | string }): void {
    if (this.data?.user?.store?.store_business_data) {
      const data = value.image;
      if (value.type == 'civil') {
        if (!this.data.user.store.store_business_data.civil_id_image) {
          /* eslint-disable @typescript-eslint/camelcase */
          this.data.user.store.store_business_data = {
            ...this.data.user.store.store_business_data,
            civil_id_image: data,
          };
        } else {
          this.data.user.store.store_business_data.civil_id_image = data;
        }
      } else if (value.type == 'freelance') {
        if (!this.data.user.store.store_business_data.freelance_certificate) {
          this.data.user.store.store_business_data = {
            ...this.data.user.store.store_business_data,
            freelance_certificate: data,
          };
        } else {
          this.data.user.store.store_business_data.freelance_certificate = data;
        }
      } else if (value.type == 'commercial') {
        if (!this.data.user.store.store_business_data.commercial_register_certificate) {
          this.data.user.store.store_business_data = {
            ...this.data.user.store.store_business_data,
            commercial_register_certificate: data,
          };
        } else {
          this.data.user.store.store_business_data.commercial_register_certificate = data;
        }
      } else if (value.type == 'maroof') {
        if (!this.data.user.store.store_business_data.maroof_certificate) {
          this.data.user.store.store_business_data = {
            ...this.data.user.store.store_business_data,
            maroof_certificate: data,
          };
        } else {
          this.data.user.store.store_business_data.maroof_certificate = data;
        }
        /* eslint-enable @typescript-eslint/camelcase */
      }
    }
  }

  @Mutation
  private UPDATE_USER_STORE_DATA(updatedData: ApiModelStoreSettingsInfoInterface): void {
    this.isSubmitting = AppStoreReadyStateEnum.loaded;
    if (this.data?.user) {
      this.data.user = updatedData.user;
    }
  }

  @Mutation
  private UPDATE_USER_STORE_NAME(): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private ON_CHANGE_SOCIAL_MEDIA(value: { value: string; type: SocialMediaTypeEnum }): void {
    if (this.data?.user?.store) {
      const data = value.value;
      this.data.user.store = {
        ...this.data.user.store,
        [value.type]: data,
      };
    }
  }

  @Mutation
  private ON_CATEGORIES_LIST_CHANGE(categoriesList: ApiModelStoreSettingsCategoryInterface[]): void {
    if (this.data) {
      this.data.categories = categoriesList;
    }
  }

  @Mutation
  private IS_OTHER_CATEGORY(value: string): void {
    if (this.data?.user?.store) {
      this.data.user.store.category_other = value; // eslint-disable-line @typescript-eslint/camelcase
    }
  }

  @Mutation
  private FETCH_CITIES_SUCCESS(data: ApiModelCityInterface[]): void {
    this.loadingCities = AppStoreReadyStateEnum.loaded;
    this.cities = data;
    if (this.data) this.data.cities = data;
    this.cities = data;
  }

  @Mutation
  private FETCH_COUNTRIES_SUCCESS(data: ApiModelCountryInterface[]): void {
    this.countries = data;
    if (this.data) this.data.countries = data;
    this.countries = data;
  }

  @Action({ rawError: true })
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async updateStoreProfile(updatedData: ApiModelUserInterface | null): Promise<any> {
    this.UPDATE();
    const response = await service.updateStoreSettingsInfo(updatedData);
    this.UPDATE_USER_STORE_DATA(response.data);
    return response;
  }

  @Mutation
  private SET_VAT_SETTINGS_ACTIVE_STATE(response: VatSettingsInterface): void {
    this.vatActivate = response.data.tax_settings.vat_activate;
  }

  @Mutation
  private SET_VAT_SETTINGS(response: VatSettingsInterface): void {
    this.vatSettings = response.data.tax_settings;
  }

  @Mutation
  FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.isSubmitting = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  SET_BUSINESS_TYPE(type: businessTypeEnum): void {
    this.businessType = type;
  }

  @Mutation
  public HANDLE_ERROR(error: AxiosError): void {
    if (error.response) {
      this.loadingState = AppStoreReadyStateEnum.error;
      const errorData = error.response.data as any;
      this.errorMessage = errorData.validations[0].errors[0];
    }
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async updateStoreBusinessPlace(
    updatedData: StoreBusinessPlaceApiFiltersInterface,
  ): Promise<ApiModelStoreSettingsInfoInterface> {
    this.UPDATE();
    const response = await service.updateStoreBusinessPlace(updatedData);
    this.UPDATE_USER_STORE_DATA(response.data);
    return response.data;
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async addLocationBusinessPlace(
    updatedData: StoreBusinessPlaceApiFiltersInterface,
  ): Promise<ApiModelStoreSettingsInfoInterface> {
    this.UPDATE();
    const response = await service.addLocationBusinessPlace(updatedData);
    this.UPDATE_USER_STORE_DATA(response.data);
    return response.data;
  }

  @Action
  @Catch()
  public async updateStoreNameOnce(updateStoreName: {
    store_name: string;
  }): Promise<ApiModelStoreSettingsInfoInterface | undefined> {
    try {
      this.UPDATE();
      const response = await service.updateStoreNameOnce(updateStoreName);
      this.UPDATE_USER_STORE_NAME();
      return response.data;
    } catch (error) {
      try {
        const axiosError = error as AxiosError;
        if ('isAxiosError' in axiosError && axiosError.isAxiosError) {
          this.HANDLE_ERROR(axiosError);
        }
      } catch (e) {
        // Handle other types of errors or log the unknown error
        console.error('Unknown error:', error);
      }
      return; // Ensure a return statement is present
    }
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async getStoreBusinessPlace(): Promise<ApiModelBusinessLocationInterface> {
    this.FETCH_BUSINESS_PLACE();
    const response = await service.getStoreBusinessPlace();
    this.FETCH_BUSINESS_PLACE_SUCCESS(response.data);
    return response.data;
  }

  @Action
  @Catch()
  public async updateAboutStoreInfo(updatedData: AboutStoreInfoApiFiltersInterface): Promise<any> {
    const response = await service.updateAboutStoreInfo(updatedData);
    this.UPDATE_USER_STORE_DATA(response.data);
    return response;
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async deleteCommercialRegisterCertificate(type: string): Promise<any> {
    const response = await service.deleteCommercialRegisterCertificate();
    this.ON_DOCUMENT_CHANGE({
      type: type,
      image: '',
    });
    return response;
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async deleteMaroofCertificate(type: string): Promise<any> {
    const response = await service.deleteMaroofCertificate();
    this.ON_DOCUMENT_CHANGE({
      type: type,
      image: '',
    });
    return response;
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async deleteFreelanceCertificate(type: string): Promise<any> {
    const response = await service.deleteFreelanceCertificate();
    this.ON_DOCUMENT_CHANGE({
      type: type,
      image: '',
    });
    return response;
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async deleteCivilIdImage(type: string): Promise<any> {
    const response = await service.deleteCivilIdImage();
    this.ON_DOCUMENT_CHANGE({
      type: type,
      image: '',
    });
    return response;
  }

  @Action
  public onChangeStoreTitle(value: InputTextChangeEventInterface): void {
    this.ON_CHANGE_STORE_TITLE(value.value);
  }

  @Action
  public onChangedNumberOfBranches(value: InputTextChangeEventInterface): void {
    this.ON_CHANGE_NUMBER_OF_BRANCHES(Number(value.value));
  }

  @Action
  public onChangeEnName(value: InputTextChangeEventInterface): void {
    this.ON_CHANGE_STORE_EN_TITLE(value.value);
  }

  @Action
  public onChangeEmail(value: InputTextChangeEventInterface): void {
    this.ON_CHANGE_STORE_EMAIL(value.value);
  }

  @Action
  public onChangePhoneNumber(value: string): void {
    this.ON_CHANGE_STORE_PHONE(value);
  }

  @Action
  public onChangeCountry(SelectedCountry: ApiModelCountryInterface): void {
    this.ON_CHANGE_COUNTRY(SelectedCountry);
  }

  @Action
  public onChangeNationalAddressCertificate(file: File | null): void {
    this.ON_CHANGE_NATIONAL_ADDRESS_CERTIFICATE(file);
  }

  @Action
  public onChangeCity(selectedCity: ApiModelCityInterface): void {
    this.ON_CHANGE_CITY(selectedCity);
  }
  @Action
  public onDistrictChange(value: InputTextChangeEventInterface): void {
    this.SET_DISTRICT_CHANGE(value.value);
  }

  @Action
  public onStreetChange(value: InputTextChangeEventInterface): void {
    this.SET_STREET_ADDRESS(value.value);
  }

  @Action
  public onBuildingNoChange(value: InputTextChangeEventInterface): void {
    this.SET_BUILDING_NO_ADDRESS(Number(value.value));
  }

  @Action
  public onPostalCodeChange(value: InputTextChangeEventInterface): void {
    this.SET_POSTAL_CODE_ADDRESS(Number(value.value));
  }

  @Action
  public onAdditionalPostalCodeChange(value: InputTextChangeEventInterface): void {
    this.SET_ADDIONAL_POSTAL_CODE_ADDRESS(Number(value.value));
  }

  @Action
  public onChangeComericalNumber(value: InputTextChangeEventInterface): void {
    this.ON_CHANGE_COMMERICAL_NUMBER(value.value);
  }

  @Action
  public onChangeComericalRegistrationNumber(value: InputTextChangeEventInterface): void {
    this.ON_CHANGE_COMMERICAL_REGISTRATION_NUMBER(value.value);
  }

  @Action
  public onStoreBusinessData(value: InputTextChangeEventInterface): void {
    this.SET_STORE_BUISNESS_DATA(value.value);
  }

  @Action
  public onIndustryChanged(value: InputTextChangeEventInterface): void {
    this.ON_INDUSTRY_CHANGE(JSON.parse(value.value));
  }

  @Action
  public onOtherCategory(value: InputTextChangeEventInterface): void {
    this.IS_OTHER_CATEGORY(value.value);
  }

  @Action
  public onCategoryChanged(value: InputTextChangeEventInterface): void {
    this.ON_CATEGORY_CHANGE(value.value !== '' ? JSON.parse(value.value) : value.value);
  }

  @Action
  public onCommercialDisplay(value: { isChecked: boolean }): void {
    this.IS_COMMERCIAL_DISPLAY(value.isChecked);
  }

  @Action
  public onAddressDisplay(value: InputCheckboxChangeEventInterface): void {
    this.SET_ADDRESS_DISPLAY(value.isChecked);
  }

  @Action
  public onLocationChange(value: { lat: number; lng: number }): void {
    this.ON_LOCATION_CHANGE(value);
  }

  @Action
  public onCategoriesListChanged(categoriesList: any): void {
    this.ON_CATEGORIES_LIST_CHANGE(categoriesList[0].store_categories);
  }

  @Action
  public onChangeSocialMedia(value: { value: string; type: SocialMediaTypeEnum }): void {
    this.ON_CHANGE_SOCIAL_MEDIA(value);
  }

  @Action
  public onChangeBusinessType(type: string): void {
    this.ON_CHANGE_BUSINESS_TYPE(type);
  }

  @Action
  public setDefaultBusinessData(): void {
    this.SET_DEFAULT_BUSINESS_DATA();
  }

  @Action
  public onChangeTimeZone(value: string): void {
    this.ON_CHANGE_TIME_ZONE(value);
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async fetch(): Promise<void> {
    this.FETCH();
    const response = await service.getStoreSettingsInfo();
    this.FETCH_SUCCESS(response.data.data);

    revalidate(response, (fresh: typeof response.data) => this.FETCH_SUCCESS(fresh.data));
  }

  @Action
  @Catch()
  public async getCitiesByCountryID(countryId: number): Promise<void> {
    this.UPDATE_CITIES();
    const response = await service.getCitiesByCountryID(countryId);
    this.FETCH_CITIES_SUCCESS(response.data);
  }

  @Action
  @Catch()
  public async getCountries(): Promise<ApiModelCountryInterface[]> {
    const response = await service.getCountries();
    this.FETCH_COUNTRIES_SUCCESS(response.data);
    return response.data;
  }

  @Action
  @Catch()
  public async getBusniessTypeFieldsForKYC(businessType?: businessTypeEnum): Promise<BusinessDetailsSchemaType> {
    this.SET_BUSINESS_TYPE(businessType ?? businessTypeEnum.individual);
    const response = await service.getBusniessTypeFieldsForKYC(businessType);
    return response.data;
  }

  @Action
  public async updateStoreBusinessDetails(businessDetails: FormData): Promise<any> {
    const response = await service.updateStoreBusinessDetails(businessDetails);
    return response;
  }

  @Action
  public async updateCategory(businessDetails: FormData): Promise<ApiBaseDataResponseObjectType> {
    const response = await service.updateCategory(businessDetails);
    return response;
  }

  @Action
  public async updateBusinessType(businessDetails: FormData): Promise<ApiBaseDataResponseObjectType> {
    const response = await service.updateBusinessType(businessDetails);
    return response;
  }
  @Action
  public async completeSetup(): Promise<any> {
    this.FETCH();
    const response = await service.completeSetup();
    this.FETCH_SUCCESS__COMPLETE();
    return response;
  }

  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  @Action
  public async getStoreVatSettings(): Promise<void> {
    const response = await service.getStoreVatSettings();
    this.SET_VAT_SETTINGS_ACTIVE_STATE(response);
    this.SET_VAT_SETTINGS(response);
  }
}

export const SettingsStoreInfoModule = getModule(StoreSettingsModule);
