<template>
  <span v-if="isMazeedNotActivated" :class="styles['fast-activation-badge']">
    <span>&#9889;</span>
    <span>{{ $t(I18nMessages['mazeed.fast_activation']) }}</span>
  </span>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { I18nMessages } from '../../../../i18n/messages';
import { MazeedModule } from '../../../store/module';
import AppStoreReadyStateEnum from '../../../../app/store/ready-state.enum';

const isMazeedNotActivated = computed((): boolean => {
  return (
    MazeedModule.storeSummaryLoadingState !== AppStoreReadyStateEnum.loading &&
    MazeedModule.storeSummary?.status !== 'active'
  );
});

onMounted(() => {
  if (!MazeedModule.storeSummary) MazeedModule.getStoreSummary();
});
</script>

<style module="styles" lang="scss">
@import '../../../../common/styles/mixins/ltr';

.fast-activation-badge {
  margin: 0;
  border: 1px solid var(--zid-colors-yellow-500);
  border-radius: var(--zid-border-radius-16);
  background: var(--zid-colors-yellow-200);
  color: var(--zid-colors-yellow-900);
  font-size: var(--zid-font-size--xs);
  padding: 1px 8px;
  margin-inline-end: -12px;
  @include ltr {
    margin-inline-start: 3px;
    padding: 1px 6px;
    margin-inline-end: unset;
  }
}
</style>
