/* eslint-disable @typescript-eslint/camelcase */
import ApiModelUserInterface from '../../../api/model/user.interface';
import { objectFilter } from '../../../common/helpers/object/filter';
import { StoreInfoApiFiltersInterface } from './store-filters.interface';
// will delete this
export function StoreInfoApiDraftsFiltersToStoreInfoData(
  updatedData: ApiModelUserInterface | null,
): StoreInfoApiFiltersInterface {
  let params: StoreInfoApiFiltersInterface;
  if (updatedData) {
    params = {
      store_name: updatedData.store.title,
      phone: updatedData.store.phone,
      email: updatedData.store.email,
      country_id: updatedData.business_location?.country.id,
      store_category_id: updatedData.store.category.id,
      commercial_registration_number: updatedData.store.commercial_registration_number
        ? updatedData.store.commercial_registration_number
        : '',
      commercial_registration_number_activation: updatedData.store.commercial_registration_number_activation ? 1 : 0,
      store_industry_id: updatedData.store?.industry?.id,
      has_branches: updatedData.store.store_business_data?.branch_no == 0 ? 0 : 1,
      business_type: updatedData.store.store_business_data?.business_type,
      maroof_number: updatedData.store.store_business_data?.maroof_number
        ? updatedData.store.store_business_data?.maroof_number
        : undefined,
      business_place_status: updatedData.store.store_business_data?.business_place_status,
      civil_id: updatedData.store.store_business_data?.civil_id ? updatedData.store.store_business_data?.civil_id : '',
      commercial_register_certificate: updatedData.store.store_business_data?.commercial_register_certificate,
      maroof_certificate: updatedData.store.store_business_data?.maroof_certificate,
      freelance_certificate: updatedData.store.store_business_data?.freelance_certificate,
      civil_id_image: updatedData.store.store_business_data?.civil_id_image,
      is_maroof_checked: !!updatedData.store.store_business_data?.maroof_certificate,
      is_freelance_checked: !!updatedData.store.store_business_data?.freelance_certificate,
      commercial_name: updatedData.store.store_business_data?.commercial_name,
      city_id: updatedData.business_location?.city?.id,
      district: updatedData.business_location?.district,
      street: updatedData.business_location?.street,
      lat: updatedData.business_location?.lat ?? undefined,
      lng: updatedData.business_location?.lng ?? undefined,
      show_location: updatedData.business_location?.show_location ? 1 : 0,
      postal_code: updatedData.business_location?.postal_code ?? undefined,
      additional_postal_code: updatedData.business_location?.additional_postal_code ?? undefined,
      building_no: updatedData.business_location?.building_no ?? undefined,
      branch_no: updatedData?.store?.store_business_data?.branch_no ?? undefined,
      employee_no: updatedData?.store?.store_business_data?.employee_no ?? undefined,
      business_corporate_name:
        updatedData?.store?.store_business_data?.business_type == 'corporate'
          ? updatedData?.store?.store_business_data?.business_corporate_name
          : undefined,
      store_category_other:
        updatedData.store.category.slug == 'others' ? updatedData?.store?.category_other : undefined,
    };

    return objectFilter(params, (key, value) => value !== undefined) as StoreInfoApiFiltersInterface;
  }

  return objectFilter({}, (key, value) => value !== undefined) as StoreInfoApiFiltersInterface;
}
