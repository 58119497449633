import { I18nMessages } from '../../../../i18n/messages';
import Vue from 'vue';
import ApiModelUserInterface from '../../../../api/model/user.interface';
import UserStoreModule from '../../../../user/store/module';
import { TranslateResult } from 'vue-i18n';
import isRTL from '../../../../common/helpers/is-lang-arabic';
import isProduction from '../../../../common/helpers/is-production';
import { PurchaseStoreModule } from '../../../store/subscription-checkout/purchase/purchase-module';

declare const Tapjsli: any;

export const EmbeddedCreditCardMixin = Vue.extend({
  data: function () {
    return {
      addForm: false,
      tap: null as any,
      card: null as any,
      creditCardError: '',
    };
  },
  props: {
    purchaseSource: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    user(): ApiModelUserInterface | null {
      return UserStoreModule.data;
    },
    lables(): Record<string, TranslateResult> {
      return {
        cardNumber: this.$t(
          I18nMessages['subscription.checkout.summary_and_payment.payment_method.embedded_credit_card.card_number'],
        ),
        expirationDate: this.$t(
          I18nMessages['subscription.checkout.summary_and_payment.payment_method.embedded_credit_card.expiration_date'],
        ),
        cvv: this.$t(I18nMessages['subscription.checkout.summary_and_payment.payment_method.embedded_credit_card.cvv']),
        cardHolder: this.$t(
          I18nMessages['subscription.checkout.summary_and_payment.payment_method.embedded_credit_card.card_holder'],
        ),
      };
    },
    styles(): any {
      return {
        base: {
          color: '#535353',
          lineHeight: '18px',
          fontFamily: 'sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#A1A0A9',
            fontSize: '15px',
          },
        },
        invalid: {
          color: `#D92C20`,
        },
      };
    },
    paymentOptions(): Record<string, any> {
      return {
        currencyCode: 'all',
        labels: this.lables,
        TextDirection: isRTL() ? 'rtl' : 'ltr',
      };
    },
    tapMerchantId(): number | null {
      if (PurchaseStoreModule.data) {
        return PurchaseStoreModule.data.common_payment_config.tap_zidcore_merchant_id;
      } else {
        return null;
      }
    },
  },
  mounted() {
    const walletTapKey = isProduction() ? 'pk_live_PEj0KpSRFo3eu9NJiLZTf1AB' : 'pk_test_uMga3iW5Sq1GbkKpmNHFxECf';
    const bluebirdPath = 'https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.3.4/bluebird.min.js';
    const goSellJSPath = 'https://secure.gosell.io/js/sdk/tap.min.js';
    this.loadDynamicScript(bluebirdPath, () => {
      this.loadDynamicScript(goSellJSPath, () => {
        const tapPublicKey =
          this.purchaseSource && this.purchaseSource === 'wallet' ? walletTapKey : this.user?.tap_public_key;
        this.tap = Tapjsli(tapPublicKey, this.tapMerchantId);
        const elements = this.tap.elements({});
        this.card = elements.create('card', { style: this.styles }, this.paymentOptions);
        this.card.mount('#form-container #root');
        this.addCardEventListener();
      });
    });
  },
  methods: {
    loadDynamicScript(src: string, callBack: Function): void {
      const script = document.createElement('script');
      script.src = src;
      document.head.appendChild(script);
      script.onload = () => {
        if (callBack) callBack();
      };
    },
    async createToken(): Promise<any> {
      await this.tap.createToken(this.card).then((result: any) => {
        if (result.error) {
          this.creditCardError = result.error.message;
        } else {
          this.$emit('tokenCreated', result);
        }
      });
    },
    addCardEventListener(): void {
      this.card.addEventListener('change', (event: any) => {
        if (event.error) {
          this.creditCardError = event.error.message;
        } else {
          if (event.error_interactive) {
            this.creditCardError = this.$t(
              I18nMessages[
                `subscription.checkout.summary_and_payment.payment_method.embedded_credit_card.tap-error-handler_${event.error_interactive.key}` as keyof typeof I18nMessages
              ],
            ) as string;
            this.$emit('disableSubmit', true);
          } else if (!event.error_interactive) {
            this.creditCardError = '';
          }

          if (event.status === 'success') {
            this.$emit('disableSubmit', false);
          }
        }
      });
    },
  },
});
