export const appMarketApiUrls = {
  myApps: '/api/v1/app-market/my-apps',
  sections: '/api/v1/app-market/sections',
  newestAppSection: '/api/v1/app-market/section/newest-apps',
  bannerSection: '/api/v1/app-market/banner-sections',
  categories: '/api/v1/app-market/categories',
  featuredApps: '/api/v1/app-market/featured-apps',
  applications: '/api/v1/app-market/applications',
  packageSuggestedApps: '/api/v1/app-market/package-suggested-apps',
  application: '/api/v1/app-market/apps',
  applicationRate: '/api/v1/app-market/rate',
  installApplication: '/api/v1/app-market/install',
  subscribeFreePlan: '/api/v1/app-market/subscription/free',
  requestPrivatePlan: '/api/v1/app-market/apps/plans/request-private',
  toggleRecurringAppSubscription: '/api/v1/app-market/app/toggle-recurring',
  uninstallApplication: '/api/v1/app-market/remove',
  uninstalReasons: '/api/v1/app-market/uninstallation-reasons',
  togglePinningApp: '/api/v1/app-market/pinned-application',
  suggestedApps: '/api/v1/app-market/suggested',
  appMarketAnalytics: '/api/v1/app-market/analytics',
  purchasePlan: '/api/v1/app-market/purchases/app',
  confirmPlanPurchase: '/api/v1/app-market/confirm',
  embeddedApp: '/api/v1/app-market/apps/embedded',
  getTestimonies: '/api/v1/partners/testimonials',
  getCollections: '/api/v1/app-market/app-collection',
  homePageSections: '/api/v1/app-market/home-page-sections',
  appsTickets: '/api/v1/managers/ticketing/create-app-ticket',
  themesTickets: '/api/v1/managers/ticketing/create-theme-ticket',
};
