import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidButton, ZidIcon, ZidModal } from '@zidsa/ui';

import { I18nMessages } from '../../../../i18n/messages';

import styles from './Upgrade.scss';

@Component
export class ModalUpgradeComponent extends Vue {
  @Prop({
    required: true,
  })
  private readonly isOpen!: boolean;

  @Prop()
  private readonly icon!: string;

  @Prop()
  private readonly iconClass!: string;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly message!: string;

  render(h: CreateElement): VNode {
    return (
      <ZidModal visible={this.isOpen} onClose={this.onCloseModal} class={styles.modal}>
        {this.renderIcon(h)}
        {this.title && <h2 class={styles.modalupgrade__title}>{this.title}</h2>}
        {this.renderMessage(h)}
        <footer class={styles.modalupgrade__footer}>
          <ZidButton onClick={this.onClickButtonConfirm} class={styles.modalupgrade__button}>
            {this.$t(I18nMessages['subscriptions.visit.subscription'])}
          </ZidButton>
          <ZidButton link onClick={this.onCloseModal} class={styles.modalupgrade__button}>
            {this.$t(I18nMessages['common.cancel'])}
          </ZidButton>
        </footer>
      </ZidModal>
    );
  }

  private renderMessage(h: CreateElement): VNode {
    if (!this.message) {
      return <div class={styles.modal__message}>{this.$t(I18nMessages['payment.options.activation.modal.msg'])}</div>;
    }

    return <div class={styles.modal__message}>{this.$t(I18nMessages['subscriptions.upgrade.package'])}</div>;
  }

  private renderIcon(h: CreateElement): VNode {
    if (!this.icon) {
      return (
        <div class={styles.modal__icon}>
          <ZidIcon icon='star_outline' color='primary' />
        </div>
      );
    }

    return (
      <div class={styles.modalupgrade__icon__container}>
        <ZidIcon icon={this.icon} color='primary' class={[styles.modalupgrade__icon, this.iconClass]} />
      </div>
    );
  }

  private onClickButtonConfirm(): void {
    this.$emit('confirm');
  }

  private onCloseModal(): void {
    this.$emit('close');
  }
}
