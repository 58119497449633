import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { ZidBadge, ZidIcon, ZidText } from '@zidsa/ui';
import UserStoreModule from '../../../user/store/module';
import { I18nMessages } from '../../../i18n/messages';
import SidebarNavigationSection from './NavigationSection/NavigationSection';
import SidebarNavigationSectionItem from './NavigationSection/Item/Item';
import { BadgeTypeEnum, BadgeTypeMenuBased } from '../../../common/components/Badge/type.enum';
import ApiModelUserInterface from '../../../api/model/user.interface';
import { navigationItems } from './navigationItems';
import { ModalUpgradeComponent } from '../../../common/components/Modal';
import copyIcon from './CustomIcons/copy-icon.svg';
import defaultStoreIcon from './CustomIcons/default-store-icon.svg';
import SubscriptionPackage from '../../../common/components/SubscriptionPackage/SubscriptionPackage.vue';

import styles from './Sidebar.scss';
import OrderStreakNotification from '../../../orders/components/OrderStreakNotification/OrderStreakNotification.vue';
import { ApiModelOrderNotificationStreakInterface } from '../../../orders/api/order-streak/response.interface';
import { OrderStreakTrackEnum } from '../../../orders/api/order-streak/order-streak.enum';

import isMobileView from '../../../common/helpers/is-mobile-view';
import MobileHeaderSectionComponent from './MobileHeaderSection/MobileHeaderSection.vue';
import { LiveIconComponent } from './CustomIcons/customIconsComponents/LiveIconComponent';
import { OfferLabelNationalDay } from '../../../app-market/components/AppMarket/OfferLabel/OfferLabelNationalDay';
import FastActivationSidebarBadge from '../../../mazeed/components/Common/FastActivationSidebarBadge/FastActivationSidebarBadge.vue';

@Component
export default class SidebarComponent extends Vue {
  @Prop({
    required: true,
  })
  private isCollapsed!: boolean;

  get userName(): string | undefined {
    return UserStoreModule.data?.name;
  }

  get userID(): number | undefined {
    return UserStoreModule.data?.store.id || undefined;
  }

  get userLogo(): string | undefined {
    return UserStoreModule.data?.store.logo || undefined;
  }

  get userStoreUrl(): string | undefined {
    return UserStoreModule.data?.store.url || undefined;
  }

  get userStoreTitle(): string | undefined {
    return UserStoreModule.data?.store.title;
  }

  get user(): ApiModelUserInterface | null {
    return UserStoreModule.data;
  }

  get hideApp(): boolean {
    if (!this.user) return true;
    return !(!!this.user.store.one_signal_keys.app_id && !!this.user.store.one_signal_keys.app_key);
  }

  private activeMenu = -1;

  private hasToggledMenuOnRouteChange = false;

  private isUpgradeModalOpen = false;

  private isStoreIdCopied = false;

  private orderStreakDays: Pick<ApiModelOrderNotificationStreakInterface, 'days_count' | 'track'> | null = null;
  render(h: CreateElement): VNode {
    return (
      <nav class={this.classes}>
        <div class={styles.sidebar__content}>
          {!isMobileView() ? (
            <div>
              <button class={styles.sidebar__closebutton} onClick={this.onClickCollapse}>
                <ZidIcon icon='close' class={styles.sidebar__closebuttonicon} />
              </button>
              <header class={styles.sidebar__header}>
                <div class={styles.sidebar__brand}>
                  <div class={styles.sidebar__brandlogowrapper}>
                    {this.userLogo ? (
                      <div class={styles.sidebar__brandlogo}>
                        <img
                          class={styles.sidebar__brandlogo_storelogo}
                          src={this.userLogo}
                          alt={this.$t(I18nMessages['sidebar.header.user_logo.alt']).toString()}
                        />
                      </div>
                    ) : (
                      <div class={styles.sidebar__brandlogo}>
                        <img
                          src={defaultStoreIcon}
                          alt='default-store-icon'
                          class={styles.sidebar__brandlogo_defaultlogo}
                        />
                      </div>
                    )}
                    <OrderStreakNotification
                      class={styles.sidebar__orderstreak}
                      onFetchCompleted={this.onOrderStreakFetchCompleted}
                    />
                  </div>
                  <div>
                    <div>
                      <a onClick={this.openStorePage} class={styles.sidebar__viewstore}>
                        <ZidIcon icon={'shortcut'} size={'12px'} class={styles.sidebar__viewstore__icon} />
                      </a>
                      {this.userName && (
                        <span class={styles.sidebar__brandtitle} data-identifier='sidebar-store-name'>
                          {this.userName}
                        </span>
                      )}
                    </div>
                    <div>
                      {!this.isStoreIdCopied ? (
                        <span class={styles.sidebar__storeid} data-identifier='sidebar-store-id'>
                          {`${this.$t(I18nMessages['common.store_id'])} : ${this.userID}`}
                          <img
                            onClick={this.onCopyUserId}
                            class={styles.sidebar__storeid_icon}
                            src={copyIcon}
                            alt='copy-icon'
                          />
                        </span>
                      ) : (
                        <ZidText class={styles.sidebar__storeid__copied} type='primary' size='sm'>
                          {this.$t(I18nMessages['common.store_copied'])}
                        </ZidText>
                      )}
                      {this.renderOrderStreakDays(h)}
                      <SubscriptionPackage />
                    </div>
                  </div>
                </div>
              </header>
            </div>
          ) : (
            <MobileHeaderSectionComponent onOrderStreakFetchCompleted={this.onOrderStreakFetchCompleted} />
          )}
          <ul class={styles.sidebar__navigation}>
            {navigationItems().map(
              (navigationItem, index) =>
                !navigationItem.hide && (
                  <SidebarNavigationSection
                    label={this.$t(navigationItem.labelTranslation)}
                    tag={navigationItem.tag && this.renderTaggedMenuItem(this.$createElement, navigationItem.tag)}
                    icon={navigationItem.icon}
                    customIcon={navigationItem.customIcon}
                    customIconHighlighted={navigationItem.customIconHighlighted}
                    iconCustomWidth={navigationItem.iconCustomWidth}
                    iconCustomHeight={navigationItem.iconCustomHeight}
                    route={navigationItem.route}
                    subRoutes={navigationItem.subRoutes}
                    href={navigationItem.href}
                    permissionRequired={navigationItem.permissionRequired}
                    subscriptionUpgradeRequired={navigationItem.subscriptionUpgradeRequired}
                    index={index}
                    activeMenu={this.activeMenu}
                    onToggleMenu={this.onToggleMenu}
                    hasToggledMenuOnRouteChange={this.hasToggledMenuOnRouteChange}
                    isSplitted={navigationItem.isSplitted}
                    isHeading={navigationItem.isHeading}
                    isExpandedByDefault={navigationItem.isExpandedByDefault}
                    linkTarget={navigationItem.isExternal && '_blank'}
                    packageCode={this.user?.store.subscription.package_code}
                    onOpenUpgradeModal={this.onOpenUpgradeModal}
                  >
                    {navigationItem.items?.map(
                      (childItem) =>
                        !childItem.hide && (
                          <SidebarNavigationSectionItem
                            route={childItem.route}
                            siblingRoutes={childItem?.siblingRoutes ?? []}
                            href={childItem.href}
                            permissionRequired={childItem.permissionRequired}
                            customIcon={childItem.customIcon}
                            icon={childItem.icon}
                            linkTarget={childItem.isExternal && '_blank'}
                            label={this.$t(childItem.labelTranslation)}
                          >
                            {childItem.tag && this.renderTaggedMenuItem(this.$createElement, childItem.tag)}
                          </SidebarNavigationSectionItem>
                        ),
                    )}
                  </SidebarNavigationSection>
                ),
            )}
          </ul>
        </div>
        <ModalUpgradeComponent
          isOpen={this.isUpgradeModalOpen}
          icon='warning'
          title={this.$t(I18nMessages['subscriptions.upgrade.package.title'])}
          onConfirm={(): string => (window.location.href = '/subscriptions')}
          onClose={(): boolean => (this.isUpgradeModalOpen = false)}
        />
      </nav>
    );
  }

  private openStorePage(): void {
    if (window.__INITIAL_STATE__.isAppWebView) {
      window.postAppMessage(
        JSON.stringify({
          action: 'open_browser',
          url: this.user?.store.url,
        }),
      );
      return;
    }

    window.open(this.user?.store.url as string, '_blank');
  }

  private renderTaggedMenuItem(h: CreateElement, itemType: BadgeTypeMenuBased): VNode | boolean {
    switch (itemType) {
      case BadgeTypeMenuBased.nationalDay:
        return <OfferLabelNationalDay />;

      case BadgeTypeMenuBased.fastActivation:
        return <FastActivationSidebarBadge />;

      default:
        return (
          <ZidBadge
            //TODO: remove custom badge class as it is temporary customized badge for mazeed
            class={itemType === BadgeTypeMenuBased.live ? styles.sidebar__livebadge : styles.sidebar__custombadge}
            type={this.mapMenuItemsToBadgeType(itemType)}
            size={'small'}
          >
            {itemType === BadgeTypeMenuBased.live ? (
              <template slot='start-icon'>
                <LiveIconComponent />
              </template>
            ) : (
              <template slot='start-icon'>
                <ZidIcon icon='fire_outline' size='12px' color='dark' />
              </template>
            )}
            {this.$t(I18nMessages[`common.${itemType}` as keyof typeof I18nMessages])}
          </ZidBadge>
        );
    }
  }

  private renderOrderStreakDays(h: CreateElement): VNode | null {
    if (
      !(
        this.orderStreakDays?.track &&
        [OrderStreakTrackEnum.onGoing, OrderStreakTrackEnum.lose].includes(this.orderStreakDays.track)
      )
    ) {
      return null;
    }

    return (
      <span
        class={[
          styles.sidebar__orderstreakdays,
          this.orderStreakDays?.track === OrderStreakTrackEnum.lose ? styles['sidebar__orderstreakdays--lose'] : '',
        ]}
      >
        {this.orderStreakDays?.days_count}
      </span>
    );
  }

  private get classes(): Record<string, boolean> {
    return {
      [styles.sidebar]: true,
      [styles['sidebar--collapsed']]: this.isCollapsed,
      [styles['sidebar--disabled']]: this.user?.store.subscription.is_suspended ?? false,
    };
  }

  private onClickCollapse(): void {
    this.$emit('close');
  }

  private onToggleMenu(menuIndex: number): void {
    this.activeMenu = this.activeMenu !== menuIndex ? menuIndex : -1;
  }

  private onOpenUpgradeModal(): void {
    this.isUpgradeModalOpen = true;
  }

  private mapMenuItemsToBadgeType(status: BadgeTypeMenuBased): BadgeTypeEnum {
    switch (status) {
      case BadgeTypeMenuBased.new:
        return BadgeTypeEnum.warning;
      case BadgeTypeMenuBased.update:
        return BadgeTypeEnum.secondary;
      case BadgeTypeMenuBased.soon:
        return BadgeTypeEnum.primary;
      case BadgeTypeMenuBased.message:
        return BadgeTypeEnum.warning;
      case BadgeTypeMenuBased.live:
        return BadgeTypeEnum.danger;
      case BadgeTypeMenuBased.nationalDay:
        return BadgeTypeEnum.danger;
      case BadgeTypeMenuBased.fastActivation:
        return BadgeTypeEnum.warning;
    }
  }

  private onCopyUserId(): void {
    const userId = this.userID?.toString();

    navigator.clipboard.writeText(userId as string);
    this.isStoreIdCopied = true;

    setTimeout(() => {
      this.isStoreIdCopied = false;
    }, 1000);
  }

  private onOrderStreakFetchCompleted(
    streakData: Pick<ApiModelOrderNotificationStreakInterface, 'days_count' | 'track'>,
  ): void {
    this.orderStreakDays = streakData;
  }

  @Watch('$route')
  onRouteChange(newRoute: Route): void {
    const menuIndex = navigationItems().findIndex((item) => item.name === newRoute.name);
    this.onToggleMenu(menuIndex);
    this.hasToggledMenuOnRouteChange = true;
  }
}
