import { PaymentNetworkEnum } from '../../api/model/payment-gateway/type.enum';
import { I18nLocalizedStringMapType } from '../../i18n/types';

export interface PaymentOptionsTypesOtherPaymentGatewayInterface {
  name: string;
  img: string;
  aboutUrl: string;
}

export enum PaymentOptionsTypesThirdPartyProvidersStatusEnum {
  active = 'Active',
  inActive = 'InActive',
  notIntegrated = 'Not Integrated',
}

export interface PaymentOptionsTypesProviderStatus {
  id: number;
  name_en: PaymentOptionsTypesThirdPartyProvidersStatusEnum;
  name_ar: string;
}

export interface PaymentOptionsTypesThirdPartyProvidersInterface {
  name: I18nLocalizedStringMapType;
  id: string;
  status: PaymentOptionsTypesProviderStatus;
  activationUrl: string;
  descriptionUrl: string;
  logoUrl: string;
}
export interface PaymentOptionsTypesThirdPartyProvidersWithZidPayStatusInterface {
  thirdPartyProviders: Array<PaymentOptionsTypesThirdPartyProvidersInterface>;
  zidpayStatus: PaymentOptionsTypesProviderStatus;
}

export interface PaymentOptionsTypesSupportedNetworksInterface {
  externalId: PaymentNetworkEnum;
  networkId: number;
  networkName: { en: string; ar: string };
  isActive: boolean;
  isAvailable: boolean;
  logoUrl: string;
}
export interface PaymentOptionsTypesStoreThirdPartyDetailsInterface {
  unPaidBiilsAmount: number;
  id: number;
  ownerFullName: string;
  mobileNumber: string;
  email: string;
  idNumber: string;
  crNumber: string;
  vatNumber: string;
  packageId: number;
  packageName: string;
  organizationName: string;
  commercialName: string;
  link: string;
  merchantStatusId: number;
  merchantStatus: name;
  storeCategory: name;
  accountTypeId: number;
  accountType: name;
  externalCode: string;
  referenceNumber: string;
  providerCode: string;
  subscriptionStatusName: name;
  merchantRisk: string;
  country: name;
  countryCode: string;
  city: string;
  fullAddress: string;
  smsDisplayName: string;
  paymentLinkWithSms: boolean;
  paymentLinkWithEmail: boolean;
  paymentLinkReminder: boolean;
  paymentLinkReminderChannel: string;
  paymentLinkPeriod: string;
  networks: PaymentOptionsTypesSupportedNetworksInterface[];
  thirdPaymentProviderId: number;
  paymentProvider: string;
  packageThreshold: number;
  lastBill: string;
  merchantThresholdOverride: null;
  dueBalance: number;
  unProcessedBalance: number;
  providerName: name;
  paymentProviderLogo: string;
  descriptionUrl: string;
  packageUpdateLocked: false;
  hasBilling: true;
}
// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface name {
  en: string;
  ar: string;
}
