import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../../app/store/factory';
import { PaymentOptionsApiServiceFactory } from '../../api/service-factory';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import { PaymentNetworkEnum } from '../../../api/model/payment-gateway/type.enum';
import { ApiBaseDataResponseObjectType } from '../../../api/types';
import { InputSwitchChangeEventInterface } from '../../../common/components/Input/Switch/change-event.interface';
import { InputTextChangeEventInterface } from '../../../common/components/Input';
import { PaymentGatewayData } from '../../types/payment-gateway-data.interface';
import initialPaymentGateway from './initial-payment-gateway-item';
import Catch from '../../../common/decorators/catch-error';
import {
  PaymentOptionsTypesProviderStatus,
  PaymentOptionsTypesStoreThirdPartyDetailsInterface,
  PaymentOptionsTypesSupportedNetworksInterface,
  PaymentOptionsTypesThirdPartyProvidersInterface,
  PaymentOptionsTypesThirdPartyProvidersStatusEnum,
  PaymentOptionsTypesThirdPartyProvidersWithZidPayStatusInterface,
} from '../../types/other-payment-gateway.interface';
import { ZidpayApiServiceFactory } from '../../../zidpay/api/service-factory';
import { SettingsStoreInfoModule } from '../../../settings/store/store-settings-module';
const zidpayService = ZidpayApiServiceFactory();
const PaymentOptionsService = PaymentOptionsApiServiceFactory();

@Module({
  dynamic: true,
  name: 'paymentGateway',
  store: appStoreFactory(),
  namespaced: true,
})
class PaymentGatewayModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: PaymentGatewayData = initialPaymentGateway;
  public storeData = {} as PaymentOptionsTypesStoreThirdPartyDetailsInterface;
  public paymetGatewayCode = '';
  public error: Error | null = null;
  public providers: Array<PaymentOptionsTypesThirdPartyProvidersInterface> | [] = [];
  public zidpaystatus: PaymentOptionsTypesProviderStatus | null = null;
  public supportedNetworks: Array<PaymentOptionsTypesSupportedNetworksInterface> = [];
  public isAnyProviderActivated: boolean = false;
  @Mutation
  private RESET_ERROR(): void {
    this.error = null;
  }

  @Mutation
  private FETCHING_DATA(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  FETCH_SUCCESS(): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.error = null;
  }
  @Mutation
  SET_STORE_PROVIDER_DETAILS_DATA(response: PaymentOptionsTypesStoreThirdPartyDetailsInterface): void {
    this.storeData = response;
    this.error = null;
  }

  @Mutation
  private SET_PAYMENT_GATEWAY(data: PaymentGatewayData): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
    this.paymetGatewayCode = data.code;
  }

  @Mutation
  private UPDATE_NETWORK_STATUS(code: PaymentNetworkEnum): void {
    this.supportedNetworks = this.supportedNetworks?.map((network) => {
      if (network.externalId === code) {
        return { ...network, isActive: !network.isActive };
      }
      return network;
    });
  }

  @Mutation
  private UPDATE_ZIDPAY_STATUS(status: PaymentOptionsTypesThirdPartyProvidersStatusEnum): void {
    if (this.zidpaystatus) this.zidpaystatus = { ...this.zidpaystatus, name_en: status };
  }

  @Action
  public setPaymentGateway(data: PaymentGatewayData): void {
    this.SET_PAYMENT_GATEWAY(data);
  }

  @Mutation
  private SET_PAYMENT_GATEWAY_CODE(code: string): void {
    this.paymetGatewayCode = code;
  }

  @Mutation
  private SET_THIRD_PARTY_PROVIDERS(providers: Array<PaymentOptionsTypesThirdPartyProvidersInterface>): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.providers = providers;
  }

  @Mutation
  private SET_ZIDPAY_sTATUS(zidpaystatus: any): void {
    this.zidpaystatus = zidpaystatus;
  }

  @Mutation
  private SET_SUPPORTED_NETWORKS(supportedNetworks: PaymentOptionsTypesSupportedNetworksInterface[]): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.supportedNetworks = supportedNetworks;
  }

  @Mutation
  private ACTIVE_DEACTIVE_ALL_THIRD_PARTY_NETWORK(switched: boolean): void {
    this.supportedNetworks = this.supportedNetworks.map((network) => {
      return {
        ...network,
        isActive: switched,
      };
    });
  }

  @Mutation
  private CHECK_ALL_PROVIDERS_ACTIVITY(
    providers: PaymentOptionsTypesThirdPartyProvidersWithZidPayStatusInterface,
  ): void {
    const isActivated = providers.thirdPartyProviders.every(
      (provider) => provider.status.name_en == PaymentOptionsTypesThirdPartyProvidersStatusEnum.active,
    );
    this.isAnyProviderActivated =
      isActivated || providers.zidpayStatus.name_en == PaymentOptionsTypesThirdPartyProvidersStatusEnum.active;
  }

  @Action
  public setPaymentGatewayCode(code: string): void {
    this.SET_PAYMENT_GATEWAY_CODE(code);
  }

  @Action
  public activeDeactiveAllThirdPartyNetwork(switched: boolean): void {
    this.ACTIVE_DEACTIVE_ALL_THIRD_PARTY_NETWORK(switched);
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async updatePaymentGateway(): Promise<ApiBaseDataResponseObjectType<PaymentGatewayData>> {
    this.RESET_ERROR();
    const response = await PaymentOptionsService.updatePaymentGateway(this.paymetGatewayCode, this.data);
    return response;
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async getThirdPartyProviders(): Promise<void> {
    this.RESET_ERROR();
    this.FETCHING_DATA();
    if (!SettingsStoreInfoModule.data) {
      await SettingsStoreInfoModule.fetch();
    }
    const response = await PaymentOptionsService.getThirdPartyProviders(
      SettingsStoreInfoModule.data?.user.language_code,
    );

    this.CHECK_ALL_PROVIDERS_ACTIVITY(response.data);
    this.SET_THIRD_PARTY_PROVIDERS(response.data.thirdPartyProviders);
    this.SET_ZIDPAY_sTATUS(response.data.zidpayStatus);
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async getStoreThirdPartyDetails(): Promise<void> {
    this.RESET_ERROR();
    this.FETCHING_DATA();
    const response = await PaymentOptionsService.getStoreThirdPartyDetails();
    this.FETCH_SUCCESS();
    this.SET_STORE_PROVIDER_DETAILS_DATA(response.data);
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async getSupportedNetworks(): Promise<void> {
    this.RESET_ERROR();
    this.FETCHING_DATA();
    const response = await PaymentOptionsService.getSupportedNetworks();
    this.SET_SUPPORTED_NETWORKS(response.data);
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async changeNetworkStatusFor3dParty({
    network,
    isEnabled,
  }: {
    network: PaymentNetworkEnum;
    isEnabled: boolean;
  }): Promise<void> {
    if (isEnabled) zidpayService.enableNetwork(network);
    else zidpayService.disableNetwork(network);

    this.FETCH_SUCCESS();
    this.UPDATE_NETWORK_STATUS(network);
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async activeAllThirdPartyNetworks(switched: boolean): Promise<void> {
    this.RESET_ERROR();
    await PaymentOptionsService.activeAllThirdPartyNetworks();
    this.activeDeactiveAllThirdPartyNetwork(switched);
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async disableAllThirdPartyNetworks(switched: boolean): Promise<void> {
    this.RESET_ERROR();
    await PaymentOptionsService.disableAllThirdPartyNetworks();
    this.activeDeactiveAllThirdPartyNetwork(switched);
  }

  @Action
  public setIsEnabled(event: InputSwitchChangeEventInterface): void {
    this.SET_IS_ENABLED(event);
  }

  @Mutation
  private SET_IS_ENABLED(event: InputSwitchChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.is_enabled = event.isSwitched;
  }

  @Action
  public setPublicKey(event: InputTextChangeEventInterface): void {
    this.SET_PUBLIC_KEY(event);
  }

  @Mutation
  private SET_PUBLIC_KEY(event: InputTextChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.public_key = event.value;
  }

  @Action
  public setSecretKey(event: InputTextChangeEventInterface): void {
    this.SET_SECRET_KEY(event);
  }

  @Mutation
  private SET_SECRET_KEY(event: InputTextChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.secret_key = event.value;
  }

  @Action
  public setPaymentOptions(event: Array<PaymentNetworkEnum>): void {
    this.SET_PAYMENT_OPTIONS(event);
  }

  @Mutation
  private SET_PAYMENT_OPTIONS(event: Array<PaymentNetworkEnum>): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.payment_options = event;
  }

  @Action
  public setToken(event: InputTextChangeEventInterface): void {
    this.SET_TOKEN(event);
  }

  @Mutation
  private SET_TOKEN(event: InputTextChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.token = event.value;
  }

  @Action
  public setProfileId(event: InputTextChangeEventInterface): void {
    this.SET_PROFILE_ID(event);
  }

  @Action
  public updateZidpayStatus(status: PaymentOptionsTypesThirdPartyProvidersStatusEnum): void {
    this.UPDATE_ZIDPAY_STATUS(status);
  }

  @Mutation
  private SET_PROFILE_ID(event: InputTextChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.profile_id = event.value;
  }

  @Action
  public setServerKey(event: InputTextChangeEventInterface): void {
    this.SET_SERVER_KEY(event);
  }

  @Mutation
  private SET_SERVER_KEY(event: InputTextChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.server_key = event.value;
  }

  @Action
  public setClientKey(event: InputTextChangeEventInterface): void {
    this.SET_CLIENT_KEY(event);
  }

  @Mutation
  private SET_CLIENT_KEY(event: InputTextChangeEventInterface): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.client_key = event.value;
  }

  @Action
  public setCountryCode(event: string): void {
    this.SET_COUNTRY_CODE(event);
  }

  @Mutation
  private SET_COUNTRY_CODE(event: string): void {
    // eslint-disable-next-line @typescript-eslint/camelcase
    this.data.settings.country_code = event;
  }
}

export const PaymentGatewayStoreModule = getModule(PaymentGatewayModule);
