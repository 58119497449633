export const zidpayApiUrls = {
  merchantDetails: '/api/v1/zidpay/merchant-details',
  nafazCode: '/api/v1/zidpay/nafaz/get-code',
  downloadMerchantAttachments: '/api/v1/zidpay/download-attachment',
  bankInfo: '/api/v1/zidpay/bank-info',
  validateIban: '/api/v1/zidpay/validate-iban',
  activateMerchant: '/api/v1/zidpay/activate-merchant',
  getTransactions: '/api/v1/zidpay/transactions',
  getCountries: '/api/v1/zidpay/countries',
  exportTransactions: '/api/v1/zidpay/transactions/export',
  getDeposits: '/api/v1/zidpay/deposits',
  getDeposit: '/api/v1/zidpay/deposits/{depositId}',
  getDeliveryStatus: '/api/v1/zidship/shipments/{shipmentId}/late-tags',
  bulkExportDeposits: '/api/v1/zidpay/deposits/export/bulk',
  exportDepositsExcel: '/api/v1/zidpay/deposits/export',
  exportDepositsPDF: '/api/v1/zidpay/deposits/exportPdf',
  getNoneDeposits: '/api/v1/zidpay/none-deposits',
  networks: '/api/v1/zidpay/networks',
  merchantProfile: '/api/v1/zidpay/merchant-profile',
  createThirdPartyMerchant: '/api/v1/zidpay/create-third-party-merchant',
  thirdPartyProviderData: '/api/v1/zidpay/get-3rd-party-details/{third_party_id}',
  getPaymentDetails: '/api/v1/zidpay/payments',
  getBanks: '/api/v1/zidpay/banks',
  getMerchantStats: '/api/v1/zidpay/merchant/stats',
  createRefund: '/api/v1/zidpay/refund/create',
  enableNetwork: '/api/v1/zidpay/network-enable',
  disableNetwork: '/api/v1/zidpay/network-disable',
  enableZidpay: '/api/v1/zidpay/zidpay-enable?providerType={provider_type}',
  updateMerchant: '/api/v1/zidpay/update-merchant',
  disableZidpay: '/api/v1/zidpay/zidpay-disable',
  getPaymentLinks: '/api/v1/zidpay/paymentlinks',
  getPaymentLinkDetails: '/api/v1/zidpay/paymentlink-details',
  reactivatePaymentLink: '/api/v1/zidpay/reactivate-paymentlink',
  cancelPaymentLink: '/api/v1/zidpay/cancel-paymentlink',
  exportPaymentLinks: '/api/v1/zidpay/paymentlinks/export',
  activatePaymentLinkViaSms: '/api/v1/zidpay/activate-paymentlink-via-sms',
  deactivatePaymentLinkViaSms: '/api/v1/zidpay/deactivate-paymentlink-via-sms',
  activatePaymentLinkViaEmail: '/api/v1/zidpay/activate-paymentlink-via-email',
  deactivatePaymentLinkViaEmail: '/api/v1/zidpay/deactivate-paymentlink-via-email',
  activatePaymentLinkReminder: '/api/v1/zidpay/activate-paymentlink-reminder',
  deactivatePaymentLinkReminder: '/api/v1/zidpay/deactivate-paymentlink-reminder',
  dismissRejectedRefundsNotification: '/api/v1/zidpay/dismiss-rejected_refunds_notification',
  getMerchantNotifications: '/api/v1/zidpay/merchant-notifications',
  updateMerchantNotifications: '/api/v1/zidpay/update-merchant-notification',
  getMerchantPackages: '/api/v1/zidpay/merchant-package',
};
