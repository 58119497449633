// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".daf55a00{margin:0;border:1px solid var(--zid-colors-yellow-500);border-radius:var(--zid-border-radius-16);background:var(--zid-colors-yellow-200);color:var(--zid-colors-yellow-900);font-size:var(--zid-font-size--xs);padding:1px 8px;margin-inline-end:-12px}html[dir=ltr] .daf55a00{margin-inline-start:3px;padding:1px 6px;margin-inline-end:unset}", "",{"version":3,"sources":["webpack://./src/mazeed/components/Common/FastActivationSidebarBadge/FastActivationSidebarBadge.vue","webpack://./src/common/styles/mixins/_ltr.scss"],"names":[],"mappings":"AAGA,UACE,QAAA,CACA,6CAAA,CACA,yCAAA,CACA,uCAAA,CACA,kCAAA,CACA,kCAAA,CACA,eAAA,CACA,uBAAA,CCVA,wBDYE,uBAAA,CACA,eAAA,CACA,uBAAA","sourcesContent":["\n@import '../../../../common/styles/mixins/ltr';\n\n.fast-activation-badge {\n  margin: 0;\n  border: 1px solid var(--zid-colors-yellow-500);\n  border-radius: var(--zid-border-radius-16);\n  background: var(--zid-colors-yellow-200);\n  color: var(--zid-colors-yellow-900);\n  font-size: var(--zid-font-size--xs);\n  padding: 1px 8px;\n  margin-inline-end: -12px;\n  @include ltr {\n    margin-inline-start: 3px;\n    padding: 1px 6px;\n    margin-inline-end: unset;\n  }\n}\n","@mixin ltr {\n  html[dir='ltr'] & {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fast-activation-badge": "daf55a00"
};
export default ___CSS_LOADER_EXPORT___;
