import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseType,
  ApiResponseType,
} from '../../api/types';
import { mazeedApiUrls } from './urls';
import {
  MazeedApiResponseInterface,
  MazeedApiRequestInterface,
  MazeedNotifyMeInterface,
} from '../types/mazeed.interface';
import { MazeedTypesActivationRequirementsDataType } from '../types/Activation/activation_reuirements_data.type';
import { MazeedTypesStoreSummaryDataType } from '../types/store-summary-data.type';
import { MazeedTypesActivationStoreProfileData } from '../types/Activation/store_profile_data.type';
import { MazeedTypesActivationIndustriesListType } from '../types/Activation/activation_industries.type';
import {
  MazeedTypeActivationBankListType,
  MazeedTypeAddNewBankAccountType,
  MazeedTypeAvailableBanksType,
  MazeedTypeCreateBankAccountType,
} from '../types/Activation/activation_banks.type';
import { MazeedTypesActivationAgreementsDataType } from '../types/Activation/activation_agreements_data.type';
import { MazeedTypesActivationSubmitBodyType } from '../types/Activation/activation_submit_body.type';
import { ProductsApiFiltersInterface } from '../../products/api/filters.interface';
import { productsApiFiltersToSearchParams } from '../../products/api/filters-to-search-params';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { ProductsApiResponseInterface } from '../../products/api/response.interface';
import {
  MazeedTypesResellerSettlementsDataType,
  MazeedTypesResellerSettlementsPaginationType,
} from '../types/mazeed_reseller_settlement.type';
import { MazeedTypesSettlementInvoiceType } from '../types/mazeed_settlement_invoice.type';
import { MazeedTypesProductMazeedInfo } from '../types/mazeed_product.type';

export class MazeedApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getMazeedSettings(): Promise<ApiBaseDataResponseObjectType<MazeedApiResponseInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<MazeedApiResponseInterface>>(
        ApiHttpMethodEnum.get,
        mazeedApiUrls.mazeedSettings,
      )
      .then((response) => response.data);
  }

  public async updateMazeedSettings(
    data: MazeedApiRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<MazeedApiResponseInterface>> {
    const url = mazeedApiUrls.mazeedSettings;

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<MazeedApiResponseInterface>>(ApiHttpMethodEnum.post, url, { data })
      .then((response) => {
        return response.data;
      });
  }

  public async registerInMazeedAnnouncement(data: MazeedNotifyMeInterface): Promise<ApiBaseMessageResponseObjectType> {
    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedAnnouncement, {
        data: {
          email: data.email,
          ['feature_code']: 'mazeed',
        },
      })
      .then((response) => response.data);
  }

  public async getActivationRequirementsChecks(): Promise<
    ApiBaseDataResponseObjectType<MazeedTypesActivationRequirementsDataType>
  > {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedCheckActivationRequirements)
      .then((response) => response.data);
  }

  public async getStoreProfileData(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<MazeedTypesActivationStoreProfileData>>
  > {
    const response = await this.apiClient.request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedStoreProfile, {
      cacheable: true,
    });
    return response;
  }

  public async getStoreSummary(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<MazeedTypesStoreSummaryDataType>>
  > {
    const response = await this.apiClient.request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedStoreSummary);
    return response;
  }

  public async updateStoreProfileData(
    data: MazeedTypesActivationStoreProfileData,
  ): Promise<ApiBaseDataResponseObjectType<MazeedTypesActivationStoreProfileData>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedStoreProfile, { data })
      .then((response) => response.data);
  }

  public async getAllIndustries(): Promise<ApiBaseDataResponseObjectType<MazeedTypesActivationIndustriesListType>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedAllIndustries)
      .then((response) => response.data);
  }

  public async getAllSaudiBanks(): Promise<ApiBaseDataResponseObjectType<MazeedTypeActivationBankListType>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedSaudiBanks)
      .then((response) => response.data);
  }

  public async getAllAvailableBankAccounts(): Promise<ApiBaseDataResponseObjectType<MazeedTypeAvailableBanksType>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedAvailableBankAccounts)
      .then((response) => response.data);
  }

  public async getAgreements(): Promise<ApiBaseDataResponseObjectType<MazeedTypesActivationAgreementsDataType>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedAgreements)
      .then((response) => response.data);
  }

  public async getMazeedProducts(
    filters: ProductsApiFiltersInterface,
  ): Promise<ApiBaseResponseType<ProductsApiResponseInterface>> {
    const params = urlQueryFormat(productsApiFiltersToSearchParams(filters));
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedProducts, { params })
      .then((response) => response.data);
  }

  public async mazeedSubmitForReview(
    data: MazeedTypesActivationSubmitBodyType,
  ): Promise<ApiBaseDataResponseObjectType<MazeedTypesActivationStoreProfileData>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedSubmitForReview, { data })
      .then((response) => response.data);
  }

  public async addProductsToMazeed(
    productIds: Array<string>,
    submitStatus: boolean,
  ): Promise<ApiBaseDataResponseObjectType<[MazeedTypesProductMazeedInfo]>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedAddProducts, {
        data: { productIds, submitStatus },
      })
      .then((response) => response.data);
  }

  public async mazeedAddNewBankAccount(
    data: MazeedTypeAddNewBankAccountType,
  ): Promise<ApiBaseDataResponseObjectType<MazeedTypeCreateBankAccountType>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedAddNewBankAccount, { data })
      .then((response) => response.data);
  }

  public async mazeedCheckIban(iban: string): Promise<ApiBaseDataResponseObjectType<{ is_valid: boolean }>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedCheckIban, { data: { iban } })
      .then((response) => response.data);
  }

  public async mazeedActivateProducts(productIds: Array<string>): Promise<ApiBaseDataResponseObjectType<{ data: {} }>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedAcitavteProducts, { data: { productIds } })
      .then((response) => response.data);
  }

  public async mazeedDisableProducts(productIds: Array<string>): Promise<ApiBaseDataResponseObjectType<{ data: {} }>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedDisableProducts, { data: { productIds } })
      .then((response) => response.data);
  }

  public async mazeedAllResellerSettlements(
    page?: number,
  ): Promise<ApiBaseDataResponseObjectType<MazeedTypesResellerSettlementsPaginationType>> {
    return this.apiClient
      .request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedAllResellerSettlements, { data: { page } })
      .then((response) => response.data);
  }

  public async mazeedAnalysisResellerSettlements(): Promise<
    ApiBaseDataResponseObjectType<MazeedTypesResellerSettlementsDataType>
  > {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedAnalysisResellerSettlements)
      .then((response) => response.data);
  }

  public async mazeedResellerSettlementInvoice(
    invoiceId: string,
  ): Promise<ApiBaseDataResponseObjectType<MazeedTypesSettlementInvoiceType>> {
    const url = mazeedApiUrls.mazeedResellerSettlementInvoice.replace('{id}', invoiceId);
    return this.apiClient.request(ApiHttpMethodEnum.get, url).then((response) => response.data);
  }

  public async mazeedDownloadResellersSettlements(): Promise<Blob> {
    return this.apiClient
      .request(ApiHttpMethodEnum.get, mazeedApiUrls.mazeedResellerSettlementDownload, {
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  public async getProductDetails(
    productId: string,
  ): Promise<ApiBaseDataResponseObjectType<MazeedTypesProductMazeedInfo>> {
    const url = mazeedApiUrls.mazeedProduct.replace('{productId}', productId);

    const response = await this.apiClient.request(ApiHttpMethodEnum.get, url, { cacheable: true });

    return response.data;
  }

  public async submitStoreMazeed(): Promise<ApiBaseDataResponseObjectType<any>> {
    const response = await this.apiClient.request(ApiHttpMethodEnum.post, mazeedApiUrls.mazeedSubmitStore);

    return response.data;
  }
}
