import ApiModelBusinessStoreDataInterface from '../../api/model/store/store-business-data.interface';

/* eslint-disable @typescript-eslint/camelcase */
const data: ApiModelBusinessStoreDataInterface = {
  business_type: 'individual',
  civil_id: null,
  civil_id_image: '',
  commercial_name: '',
  freelance_certificate: '',
  maroof_certificate: '',
  branch_no: '',
  employee_no: '',
  business_corporate_name: '',
  business_place_status: 'new',
  email: '',
  has_branches: false,
  is_freelance_checked: false,
  is_maroof_checked: true,
  maroof_number: null,
  commercial_register_certificate: '',
};

export default data;
