/* eslint-disable @typescript-eslint/camelcase */
import { objectFilter } from '../../../common/helpers/object/filter';
import { AboutStoreInfoApiFiltersInterface } from './about-store.interface';

export function AboutStoreInfoApiDraftsFiltersToStoreInfoData(
  updatedData: AboutStoreInfoApiFiltersInterface | null,
): AboutStoreInfoApiFiltersInterface {
  let params: AboutStoreInfoApiFiltersInterface;
  if (updatedData) {
    params = {
      store_name: updatedData.store_name,
      phone: updatedData.phone,
      email: updatedData.email,
      maroof: updatedData.maroof ?? undefined,
      business_center: updatedData.business_center ?? undefined,
      website: updatedData.website ?? null,
      facebook: updatedData.facebook ?? undefined,
      instagram: updatedData.instagram ?? undefined,
      twitter: updatedData.twitter ?? undefined,
      tiktok: updatedData.tiktok ?? undefined,
      snapchat: updatedData.snapchat ?? undefined,
    };

    return objectFilter(params, (key, value) => value !== undefined) as AboutStoreInfoApiFiltersInterface;
  }

  return objectFilter({}, (key, value) => value !== undefined) as AboutStoreInfoApiFiltersInterface;
}
