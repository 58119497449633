export enum MixpanelEvents {
  clickEmailRegister = 'Click Email register',
  clickGoogleRegister = 'Click Google register',
  visitRegistrationPage = 'Visit Registration Page',
  clickLogin = 'Click Login',
  fillEmail = 'Fill Email',
  clickSendOTP = 'Click send OTP',
  fillOTP = 'Fill OTP',
  clickVerify = 'Click verify',
  verificationStatus = 'Verification Status',
  visitPreparingPage = 'Visit Preparing Page',
  clickSubmitStoreName = 'Click submit store name',
  submitMobileNumber = 'Submit mobile number',
  fillCategory = 'Fill Category',
  submitCategory = 'Submit Category',
  chooseLocationType = 'Choose Location type',
  submitLocation = 'Submit Location',
  chooseBusinessType = 'Choose business type',
  clickCreateStore = 'Click Create store',
  visitSubscriptionPage = 'Visit subscription page',
  choosePackageDuration = 'Choose Package Duration',
  choosePackage = 'Choose Package',
  visitPaymentPage = 'Visit payment page',
  completePayment = 'Complete payment',
  clickEnglishLanguage = 'Click English language',
  clickArabicLanguage = 'Click Arabic language',
  visitOrdersPage = 'Visit Orders Page',
  clickDrafts = 'Click Drafts',
  clickOrdersStatusMessages = 'Click Orders status messages',
  clickCreateNewOrder = 'Click Create new order',
  clickAnalytics = 'Click Analytics',
  chooseOrdersFilter = 'Choose orders filter',
  chooseNumberOfOrdersDisplayedFilter = 'Choose number of orders displayed filter',
  searchFilter = 'Search filter',
  clickExportSllOrders = 'Click Export all orders',
  cancelExportOrders = 'Cancel export orders',
  editEmailExportOrders = 'edit email - export orders',
  confirmExportOrders = 'Confirm export orders',
  clickPrintWaybill = 'Click print waybill',
  clickChangeOrderStatus = 'Click Change order status',
  clickChangePaymentStatus = 'Click Change payment status',
  clickDetermineDateOfReceivingTheOrder = 'Click determine date of receiving the order',
  selectOrder = 'Select order',
  openOrderDetailesSlideCard = 'open order detailes slide card',
  clickOrderId = 'click order id',
  viewDraftsPage = 'view drafts page',
  clickAddNewDraft = 'Click add new draft',
  viewAddingProductPage = 'View adding product page',
  searchProduct = 'search product',
  addSelectedProducts = 'add selected products',
  changeQuantity = 'change quantity',
  removeProduct = 'remove product',
  clickNext = 'Click next',
  viewCustomerInformationPage = 'View customer information page',
  searchCustomer = 'search customer',
  addNewCustomer = 'add new customer',
  addNewAddress = 'add new address',
  chooseAddress = 'choose address',
  chooseDifferentOrderReciever = 'choose different order reciever',
  viewPaymentShippingMethodPage = 'View payment & shipping method page',
  selectPaymentMethod = 'select payment method',
  viewOrderSummaryPage = 'View Order Summary page',
  clickConfirmation = 'Click confirmation',
  visitProductPage = 'Visit Product Page',
  clickProductFiltrationSettings = 'Click Product Filtration Settings',
  visitProductFiltrationSettingsPage = 'Visit Product Filtration Settings page',
  clickProductCategories = 'Click Product Categories',
  visitProductCategories = 'Visit Product Categories',
  chooseAddNewProduct = 'Choose add new product',
  chooseAddNewDigitalProduct = 'Choose add new digital product',
  clickNormalProductsView = 'Click normal products view',
  clickDigitalProductsView = 'Click digital products view',
  clickArrangeManually = 'Click Arrange Manually',
  clickFilterProducts = 'Click Filter Products',
  chooseExportSelectedProducts = 'Choose Export selected products',
  chooseExportAllProducts = 'Choose Export all products',
  chooseEditSelectedProducts = 'Choose Edit selected products',
  chooseEditSllProducts = 'Choose Edit all products',
  chooseDeleteProducts = 'Choose delete products',
  selectAProduct = 'Select a product',
  chooseDuplicateAProduct = 'Choose duplicate a product',
  chooseDeleteAProduct = 'Choose delete a product',
  chooseCustomizeSimilarProducts = 'Choose customize similar products',
  chooseEditAProduct = 'Choose Edit a product',
  clickOnAProduct = 'Click on a product',
  clickImportProducts = 'Click import products',
  clickCustomerName = 'Click customer name',
  clickProductsSales = 'Click Products sales',
  openProductInfoCard = 'Open product info card',
  globalSearch = 'Global search',
  clickExportProducts = 'Click Export Products',
  clickAddNewProduct = 'Click add new product',
  clickMoreAction = 'Click more action',
  clickOnaProductActions = 'Click on a product actions',
  clickVerifyEmailOTP = 'Click verify Email OTP',
  clickVerifyPhoneOTP = 'Click verify Phone OTP',
  clickZamMainHelpCenter = 'Click ZAM main help center',
  clickZamMainAppsManagement = 'Click ZAM main apps management',
  clickZamMainAppsSearch = 'Click ZAM main apps search',
  clickZamScrollFeaturedApps = 'ZAM scroll featured apps',
  clickZamFeaturedApp = 'Click ZAM featured app',
  clickZamScrollAppTestimonials = 'ZAM scroll app testimonials',
  clickZamClickAppTestimonial = 'ZAM click app testimonial',
  clickZamAllCategories = 'Click ZAM all categories',
  clickZamViewCategory = 'Click ZAM view category',
  clickZamViewCategories = 'Click ZAM browse all categories',
  clickZamChoiceApp = 'Click ZAM application card',
  clickZamSuggestApp = 'Click ZAM suggest app',
  clickZamPartnerRegisteration = 'Click open partner Partner Dashboard',
  enableZAMRecurring = 'Enable ZAM App recurring',
  clickZamBrowseAppsFilters = 'Click ZAM browse apps filters',
  clickZamBrowseApps = 'Click ZAM browse apps',
  clickZamActivateApp = 'Click ZAM activate app',
  clickZamActivateFreeApp = 'Click ZAM activate free app',
  clickZamDeactivateApp = 'Click ZAM deactivate app',
  clickZamSearchAppCategory = 'Click ZAM search by app',
  sortZamApps = 'Sort ZAM apps',
  clickZamAppViewPlans = 'Click ZAM app view plans',
  clickZamAppViewReviews = 'Click ZAM app view reviews',
  clickZamViewSubscriptionPlans = 'Click ZAM app view subscription plans',
  clickZamSubscribeToTrialPlan = 'Click ZAM subscribe to free trial',
  clickZamSubscribeToFreePlan = 'Click ZAM subscribe to free plan',
  clickZamSubscribeToPlan = 'Click ZAM subscribe to plan',
  enableZAMPlanRecurring = 'Enable ZAM Plan recurring',
  clickZamMoveToPayment = 'Click ZAM move to payment',
  clickZamUpgradePlan = 'Click ZAM upgrade plan',
  clickZamOpenApp = 'Click ZAM open app',
  clickZamReportAppIssueConfirm = 'Click ZAM report app issue confirm',
  clickZamReportAppIssue = 'Click ZAM report app issue',
  rateZamApp = 'Rate ZAM app',
  clickZamScrollAppCollections = 'ZAM scroll app collections',
  clickZamViewCollection = 'Click ZAM view collection',
  zamPaymentSuccess = 'ZAM payment success',
  zamPaymentFailure = 'ZAM payment failure',
  zamShowSubscriptionModal = 'View ZAM purchase modal',
  zamUpgradePlanBtn = 'Click ZAM upgrade plan button',
  zamCancelAppActivation = 'ZAM cancel app activation',
  zamOpenZAMHome = 'ZAM view home page',
  zamScrollZAMHomeBanner = 'ZAM scroll banner',
  zamScrollZAMHomeCollection = 'ZAM scroll collection',
  zamScrollZAMHomeFeaturedApps = 'ZAM scroll featured apps',
  zamClickZAMBanner = 'Click ZAM banner card',
  productsFilterByInventory = 'Products Filter by inventory',
  productsFilterByPublishingStatus = 'Products Filter by publishing status',
  productsFilterByCategories = 'Products Filter by category',
  productsFilterByAvailability = 'Products Filter by availability',
  productsFilterByDiscountStatus = 'Products Filter by discount status',
  productsFilterByPrice = 'Products Filter by price',
  productsFilterByAvailableQuantity = 'Products Filter by available quantity',
  clickStartBusiness = 'Click Start Business',
  clickDemo = 'Click demo',
  sendNewOtpCode = 'Send new OTP code',
  loginWithPassword = 'Login with password',
  changeEmail = 'Change email',
  clickForgotPassword = 'Click Forgot Password',
  clickLoginwithOTP = 'Login with OTP',
  answerPreviousExperienceQuestion = 'Previous Experience Question',
  clickPreviousExperienceQuestionYesAnswer = 'Previous Experience Question “Yes” Answer',
  clickPreviousExperienceQuestionNoAnswer = 'Previous Experience Question No Answer',
  answerFirstStoreQuestion = 'First Store Question',
  clickFirstStoreQuestionYesAnswer = 'First Store Question “Yes” Answer',
  clickFirstStoreQuestionNoAnswer = 'First Store Question “No” Answer',
  answerPreviousMethodForSellingProductsQuestion = 'Previous Method for Selling Products Question',
  clickPreviousMethodForSellingProductsQuestionISellOnSocialMediaAnswer = 'Previous Method for Selling Products Question “I sell on social media” Answer',
  clickPreviousMethodForSellingProductsQuestionISellOnPhysicalStoreAnswer = 'Previous Method for Selling Products Question “I sell on physical store" Answer',
  answerPreviousPlatformThatHostedTheStoreQuestion = 'Previous Platform that Hosted the Store Question',
  clickPreviousPlatformThatHostedTheStoreQuestionZidAnswer = 'Previous Platform that Hosted the Store Question “Zid” Answer',
  clickPreviousPlatformThatHostedTheStoreQuestionOnOtherPlatformsAnswer = 'Previous Platform that Hosted the Store Question “On other platforms” Answer',
  answerMigrateStoreQuestion = 'Migrate Store Question',
  clickMigrateStoreQuestionYesAnswer = 'Migrate Store Question “Yes” Answer',
  clickMigrateStoreQuestionNoAnswer = 'Migrate Store Question No Answer',
  answerPlatformToMigrateFromQuestion = 'Platform to Migrate From Question',
  clickPlatformToMigrateFromQuestionSallaAnswer = 'Platform to Migrate From Question “Salla” Answer',
  clickPlatformToMigrateFromQuestionShopifyAnswer = 'Platform to Migrate From Question "Shopify" Answer',
  clickPlatformToMigrateFromQuestionMakaneAnswer = 'Platform to Migrate From Question "Makane" Answer',
  clickPlatformToMigrateFromQuestionExpandCartAnswer = 'Platform to Migrate From Question "Expand Cart" Answer',
  clickPlatformToMigrateFromQuestionOtherAnswer = 'Platform to Migrate From Question "Other" Answer',
  answerMonthlySalesVolumeQuestion = 'Monthly Sales Volume Question',
  clickMonthlySalesVolumeQuestionLessThan40KAnswer = 'Monthly Sales Volume Question “Less than 40,000” Answer',
  clickMonthlySalesVolumeQuestion40To100KAnswer = 'Monthly Sales Volume Question “Between 40,000 and 100,000” Answer',
  clickMonthlySalesVolumeQuestion101To500KAnswer = 'Monthly Sales Volume Question “Between 101,000 and 500,000” Answer',
  clickMonthlySalesVolumeQuestion501To2MAnswer = 'Monthly Sales Volume Question “Between 501,000 and 2 milion” Answer',
  clickMonthlySalesVolumeQuestionMoreThan2MAnswer = 'Monthly Sales Volume Question “More than 2 million” Answer',
  clickNextToSubmitAnswers = 'Click Next to Submit Answers',
  changePhoneNumberCountryCode = 'Change Phone Number Country Code',
  sendNewCode = 'Send New Code',
  changePhoneNumber = 'Change Phone Number',
  fillAddressAutomatically = 'Fill Address Automatically',
  fillAddressManually = 'Fill Address Manually',
  undoSettingAddress = 'Undo Setting Address',
  clickLogoutArrow = 'Click Logout Arrow',
  signOut = 'Sign Out',
  undoSigningOut = 'Undo Signing Out',
  changeEmailForLoginFlow = 'Change Email (Login Flow)',
  clickVerifyForLoginFlow = 'Click verify (Login Flow)',
  clickVerifyEmailOTPForLoginFlow = 'Click verify Email OTP (Login Flow)',
  sendNewOtpCodeForLoginFlow = 'Send new OTP code (Login Flow)',
  loginWithPasswordForLoginFlow = 'Login with password (Login Flow)',
  resetPassword = 'Reset Password',
  clickLoginByGoogle = 'Click Login by Google',
}
