export const paymentOptionsApiUrls = {
  paymentOptions: '/api/v1/account/settings/payment-options',
  banks: '/api/v1/account/settings/payment-options/banks',
  banksAccounts: '/api/v1/account/settings/payment-options/bank-transfer',
  banksAccountsStatus: '/api/v1/account/settings/payment-options/bank-transfer/status',
  gateways: '/api/v1/account/settings/payment-options/gateways',
  updateGateway: '/api/v1/account/settings/payment-options/gateways/{paymentGatewayCode}',
  thirdPartyProviders: '/api/v1/zidpay/get-available-third-party-providers',
  thirdPartyDetailsByStoreId: '/api/v1/zidpay/get-store-thrid-party-details',
  supportedNetworks: '/api/v1/zidpay/get-supported-networks',
  activeAllThirdPartyNetworks: '/api/v1/zidpay/active-all-third-party-networks',
  disableAllThirdPartyNetworks: '/api/v1/zidpay/disable-all-third-party-networks',
  addBankAccount: '/api/v1/account/settings/payment-options/bank-transfer/add',
  editBankAccount: '/api/v1/account/settings/payment-options/bank-transfer/{bankAccountId}',
  deletebankAccount: '/api/v1/account/settings/payment-options/bank-transfer/{bankAccountId}',
};
