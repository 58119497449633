import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import { ApiModelCityInterface } from '../../../api/model/city.interface';
import ApiModelUserInterface from '../../../api/model/user.interface';
import { VatSettingsInterface } from '../../../api/model/vat-settings.interface';
import { ApiModelStoreSettingsInfoInterface } from '../../../api/store-info.interface';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../../api/types';
import { StoreInfoApiDraftsFiltersToBusinessPlaceData } from '../../../orders/api/drafts/filters-to-store-business-place-data';
import { StoreBusinessPlaceApiFiltersInterface } from '../../../orders/api/drafts/store-business-place-store.interface';
import { AboutStoreInfoApiFiltersInterface } from '../../../orders/api/drafts/about-store.interface';
import { AboutStoreInfoApiDraftsFiltersToStoreInfoData } from '../../../orders/api/drafts/filters-to-about-store-data';
import { SettingsApiLocationCountriesResponseInterface } from '../location/countries/response.interface';
import { settingsApiUrls } from '../urls';
import { businessTypeEnum } from './business-type.enum';
import { BusinessDetailsSchemaType } from '../../types/business-detail-schema.interface';
import { StoreInfoApiDraftsFiltersToStoreInfoData } from '../../../orders/api/drafts/filters-to-store-profile-data';

import Catch from '../../../common/decorators/catch-error';
import ApiModelBusinessLocationInterface from '../../../api/model/store/business-location.interface';

export class SettingsApiStoreSettingsService {
  constructor(private readonly apiClient: ApiClient) {}
  public async getStoreSettingsInfo(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>
  > {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.storeSettingsInfo,
      { cacheable: true },
    );
    return response;
  }

  public async updateStoreSettingsInfo(
    updatedData: ApiModelUserInterface | null,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>> {
    const params = StoreInfoApiDraftsFiltersToStoreInfoData(updatedData);
    const data = new FormData();

    for (const [key, value] of Object.entries(params)) {
      data.append(`${key}`, value);
    }
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateStoreSettingsInfo,
      {
        data: data,
      },
    );
    return response.data;
  }

  public async updateStoreBusinessPlace(
    updatedData: StoreBusinessPlaceApiFiltersInterface,
    options?: {
      isSetup?: boolean;
    },
  ): Promise<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>> {
    const params = StoreInfoApiDraftsFiltersToBusinessPlaceData(updatedData);
    const data = new FormData();

    for (const [key, value] of Object.entries(params)) {
      data.append(`${key}`, value);
    }
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateStoreBusinessPlace,
      {
        data: data,
        params: {
          ['is_setup']: options?.isSetup ?? false,
        },
      },
    );
    return response.data;
  }

  public async addLocationBusinessPlace(
    updatedData: StoreBusinessPlaceApiFiltersInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>> {
    const params = StoreInfoApiDraftsFiltersToBusinessPlaceData(updatedData);
    const data = new FormData();

    for (const [key, value] of Object.entries(params)) {
      data.append(`${key}`, value);
    }
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.locationBusinessPlace,
      {
        data: data,
        params: {
          ['is_setup']: updatedData?.['is_setup'] ?? false,
        },
      },
    );
    return response.data;
  }

  public async updateStoreNameOnce(updateStoreName: {
    store_name: string;
  }): Promise<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateStoreNameOnce,
      {
        data: updateStoreName,
      },
    );
    return response.data;
  }

  public async getStoreBusinessPlace(): Promise<ApiBaseDataResponseObjectType<ApiModelBusinessLocationInterface>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelBusinessLocationInterface>>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.getStoreBusinessPlace,
    );
    return response.data;
  }

  public async updateAboutStoreInfo(
    updatedData: AboutStoreInfoApiFiltersInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>> {
    const params = AboutStoreInfoApiDraftsFiltersToStoreInfoData(updatedData);
    const data = new FormData();

    for (const [key, value] of Object.entries(params)) {
      data.append(`${key}`, value);
    }
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelStoreSettingsInfoInterface>>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateAboutStoreInfo,
      {
        data: data,
      },
    );
    return response.data;
  }

  public async deleteCommercialRegisterCertificate(): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.delete,
      settingsApiUrls.deleteCommercialRegisterCertificate,
    );
    return response.data;
  }

  public async deleteMaroofCertificate(): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.delete,
      settingsApiUrls.deleteMaroofCertificate,
    );
    return response.data;
  }

  public async deleteFreelanceCertificate(): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.delete,
      settingsApiUrls.deleteFreelanceCertificate,
    );
    return response.data;
  }

  public async deleteCivilIdImage(): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.delete,
      settingsApiUrls.deleteCivilIdImage,
    );
    return response.data;
  }

  public async getCitiesByCountryID(
    countryId: number,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelCityInterface[]>> {
    const url = settingsApiUrls.getCitiesByCountryID.replace('{countryId}', countryId.toString());
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelCityInterface[]>>(
      ApiHttpMethodEnum.get,
      url,
    );
    return response.data;
  }

  public async getCountries(): Promise<SettingsApiLocationCountriesResponseInterface> {
    const response = await this.apiClient.request<SettingsApiLocationCountriesResponseInterface>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.countries,
    );

    return response.data;
  }

  public async getBusniessTypeFieldsForKYC(
    businessType?: businessTypeEnum,
  ): Promise<ApiBaseDataResponseObjectType<BusinessDetailsSchemaType>> {
    const url = `${settingsApiUrls.getBusniessTypeFieldsForKYC}${
      businessType ? `?business_type=${businessType.toString()}` : ''
    }`;

    const response = await this.apiClient.request(ApiHttpMethodEnum.get, url, { cacheable: true });
    return response.data;
  }

  @Catch()
  public async updateStoreBusinessDetails(updatedData: FormData): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateStoreBusniessDetails,
      {
        data: updatedData,
      },
    );
    return response.data;
  }

  @Catch()
  public async updateCategory(updatedData: FormData): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateCategory,
      {
        data: updatedData,
      },
    );
    return response.data;
  }

  @Catch()
  public async updateBusinessType(updatedData: FormData): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateBusinessType,
      {
        data: updatedData,
      },
    );
    return response.data;
  }

  @Catch()
  public async completeSetup(): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.completeSetup,
    );
    return response.data;
  }

  public async getStoreVatSettings(): Promise<VatSettingsInterface> {
    const response = await this.apiClient.request<VatSettingsInterface>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.getVatSettings,
      { cacheable: true },
    );

    return response.data;
  }
}
