/* eslint-disable @typescript-eslint/camelcase */
import { objectFilter } from '../../../common/helpers/object/filter';
import { StoreBusinessPlaceApiFiltersInterface } from './store-business-place-store.interface';

export function StoreInfoApiDraftsFiltersToBusinessPlaceData(
  updatedData: StoreBusinessPlaceApiFiltersInterface,
): StoreBusinessPlaceApiFiltersInterface {
  let params: StoreBusinessPlaceApiFiltersInterface;
  if (updatedData) {
    params = {
      country_id: updatedData?.country_id,
      city_id: updatedData?.city_id,
      district: updatedData?.district ?? undefined,
      street: updatedData?.street ?? undefined,
      lat: updatedData?.lat ?? undefined,
      lng: updatedData?.lng ?? undefined,
      show_location: updatedData?.show_location ? 1 : 0,
      ['add_inventory']: updatedData?.add_inventory ? 1 : 0,
      postal_code: updatedData?.postal_code ?? undefined,
      additional_postal_code: updatedData?.additional_postal_code ?? undefined,
      building_no: updatedData?.building_no ?? undefined,
      national_address_certificate: updatedData?.national_address_certificate ?? undefined,
      is_address_confirmed: updatedData.is_address_confirmed ?? undefined,
      default_timezone: updatedData.default_timezone ?? undefined,
    };

    return objectFilter(params, (key, value) => value !== undefined) as StoreBusinessPlaceApiFiltersInterface;
  }

  return objectFilter({}, (key, value) => value !== undefined) as StoreBusinessPlaceApiFiltersInterface;
}
